.customer-thumb {
  display: flex;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  width: 100%;
}

.customer-thumb .label-wrapper {
  border-right: 1px solid #f5f5f5;
  width: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 7px;
  text-align: center;
}

.customer-thumb .label-wrapper img {
  max-width: 80px;
  max-height: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.customer-thumb .details-list-wrapper {
  flex: 1;
}

.customer-thumb .label-wrapper span {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.6rem;
  color: #757575;
}

.customer-thumb .name {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.1rem;
  color: #444;
  display: block;
}
.customer-thumb .phone {
  font-size: 0.9rem;
  display: block;
}
