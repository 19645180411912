
.keypad-modal form {
  width: 100%;
}

.keypad-modal form input {
  width: 80%;
  min-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.keypad-modal .order-inputs-inline-total-paid-labels div,
.keypad-modal .runner-login-form label,
.keypad-modal label.large-label {
  color: #9b9b9b;
}
.keypadControl {
  width: 100px;
  height: 100px;
  margin: 10px 0;
}
.keypad {
  padding: 10px 0;
}
.keypad .btn,
.keypad .btn:active,
.keypad .btn:active {
  width: 100px;
  height: 100px;
  margin: 10px 0;
  font-size: 2em !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: #333;
  font-weight: 400;
  border: 2px solid #f7f7f7;
  background-color: #f9f9f9 !important;
  padding: 6px 0 !important;
  -webkit-box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.75);
}

.keypad .btn:hover {
  -webkit-box-shadow: 0px 2px 6px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 6px -4px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 6px -4px rgba(0,0,0,0.75);
  border: 2px solid #f7f7f7;
  color: #333;
}

.keypad .btn.btn-danger {
  background: #F03434 !important;
  border-color: #F03434;
  color: #fff;
  font-size: 1.1rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.keypad .btn.btn-success {
  background: #60D470 !important;
  border-color: #60D470;
  color: #fff;
  font-size: 1.1rem !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}
