.FG-navbar {
  background: #f9f9f9;
  border-bottom: 2px solid #fdfdfd;
  height: 90px;
}

.FG-navbar .logout-btn.btn,
.FG-navbar .logout-btn.btn:hover,
.FG-navbar .logout-btn.btn:active,
.FG-navbar .logout-btn.btn:focus,
.FG-navbar .logout-btn.btn:focus:active {
  background: transparent;
  border-width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.FG-navbar .logout-btn img {
  max-width: 30px;
  max-height: 30px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.FG-navbar .logout-btn label {
  font-size: 0.7rem;
  color: #b2b2b2;
}

.brand-image {
  position: absolute;
  left: 50%;
  margin-left: -40px;
}

.brand-image img {
  max-height: 70px;
  height: 70px;
}
