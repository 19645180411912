/* Main color: rgb(240, 52, 52)/#f03434 (red color) */
html, body {
  margin: 0;
  height:100%;
}
body {
  min-height: 100%;
  font-size: 14px;
  color: #9b9b9b;
  font-family: 'Montserrat', sans-serif !important;
/*  background-color: #fcc45d !important;*/
/*  background-color: #00fde0 !important;*/
}

@media all and (min-width: 1360px) {
  body {
    font-size: 18px;
  }
}

h1, h2, h3, h4, h5, p, label {
  font-family: 'Montserrat', sans-serif;
}

#root {
  font-family: 'Montserrat', Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  font-variant: normal;
  overflow: hidden;
}

/* GENERAL LAYOUT STYLES */

.main-content-wrapper {
  position: relative;
  flex: 1;
}

.flex {
  display: flex;
}


.justify-content-center {
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  color:black;
  margin: auto;
}

.btn.btn-danger {
  background: #F90021;
  border-color: #F90021;
}
.btn.btn-primary {
  background: #60D470;
  border-color: #60D470;
}
.btn.btn-default {
  color: #989898;
  background: #ededed;
}
.btn.btn-primary,
.btn.btn-danger,
.btn.btn-default {
  font-weight: bold;
  letter-spacing: 0.7px;
}
.btn-link {
  border-width: 0px;
  background: transparent
}
.modal-footer.buttons-footer {
  display: flex;
  justify-content: space-between;
}
.modal-footer.buttons-footer .btn {
  min-width: 120px;
}
.App-logo {
  max-width: 100px;
  display: inline-block;
}
.App-title {
  font-size: 2.5em;
  vertical-align: middle;
  display: inline-block;
  color: #2a2727;
}
.waitTimeText, .waitTime {
  font-size:30px;
  font-weight:bold
}
.waitTime{
  color: green;
}
.waitListTable {
  height: 400px;
  border: 2px solid orange;
  font-size: 2em;
}
/* END Homescreen Diamonds */
.full-modal {
  width: 80%;
  max-width: 550px;
}
.full-modal .modal-header {
  display: none;
}
.full-modal .modal-body {
  padding: 0;
}
.status-screen-header {
  font-size: 2rem;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
}
.lockers-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 590px;
  margin: 30px auto;
  justify-content: center;
  min-height:600px
}
.lockerButton {
  position: relative;
  border-width: 0px !important;
  margin: 8px;
  height: 180px;
  max-width: 180px;
  font-size: 5em !important;
  transition: all .2s ease-in-out !important;
}
.listview .lockerButton {
  width: 180px;
}
.locker-list-item .lockerButton,
.locker-column .lockerButton {
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 120px;
  /* background-position: 110px 33px; */
}
.locker-list-item .lockerButton.locker-ubereats,
.locker-column .lockerButton.locker-ubereats {
  background-image: url(../images/icons/ubereats-logo-small.png);
}
.locker-list-item .lockerButton.locker-doordash,
.locker-column .lockerButton.locker-doordash {
  background-image: url(../images/icons/doordash-logo-small.png);
}
.locker-list-item .lockerButton.locker-grubhub,
.locker-column .lockerButton.locker-grubhub {
  background-image: url(../images/icons/grubhub-logo-small.png);
}
.locker-list-item .lockerButton.disabled-locker,
.locker-column .lockerButton.disabled-locker,
.locker-list-item .lockerButton.disabled-locker:active,
.locker-column .lockerButton.disabled-locker:active {
  background-color: #3d3d3d !important;
  background-image: url(../images/icons/disabled.png);
}
/* make order/mail/reservation button */
.reservation-button {
  height: 100%;
}
.pickup-instruction-container {
  color: #38274D;
}
.pickup-instruction {
  display: flex;
}
.pickup-instruction-text {
  font-size: 14px;
  font-weight: 600;
  flex:1
}
.pickup-instruction-number {
  margin-right: 10px;
  font-size: 20px;
  border: 1px solid transparent;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-weight: bold;
  background-color: lavender;
  text-align: center;
}
.pickup-instruction-information {
  display: block;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  padding: 0 80px;
}
.see-u {
  opacity: .6;
  text-align: center;
}
.instruction-modal .confirm-timer-cmp {
  width: 100%;
  display: block;
  font-size: 24px;
  margin: 10px auto 0;
}
.instruction-modal .btn-submit {
  width: 40%;
  height: 60px;
  margin: 0 3%;
}
/* submit button */
.delivery-service-input-form .btn.btn-submit.btn-secondary {
  margin: 20px 0 0;
  width: 100%;
  height: 70px;
}
.delivery-service-input-form .btn.btn-submit.btn-secondary[disabled],
.delivery-service-input-form .btn.btn-submit.btn-secondary .disabled {
  background: rgba(96, 212, 112, .7);
  border-color: rgba(96, 212, 112, .7);
  width: 300px;
  height: 70px;
  opacity: 1;
}

.btn-submit {
  -webkit-transition: .5s;
  transition: .5s;
}
.lockerButton.selected {
  box-shadow: black 0px 0px 5px 3px;
}
@media (hover: hover) {
  .lockerButton:hover {
    box-shadow: black 0px 0px 5px 2px;
  }
}
.lockerButton .number {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 700;
  color: #fff;
}

.lockerButton .handle {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -5px;
  height: 10px;
  width: 10px;
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
}

.show-customer-locker-cmp {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.show-customer-locker-cmp h2 {
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.show-customer-locker-cmp .locker-pin-boxes {
  display: flex;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
}

.show-customer-locker-cmp .locker-pin-boxes .box {
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  flex: 1;
  color: #333;
  padding: 30px 15px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}
.show-customer-locker-cmp .locker-pin-boxes .box:first-child {
  margin-right: 10px;
}
.show-customer-locker-cmp .locker-pin-boxes .box:last-child {
  margin-left: 10px;
}

.show-customer-locker-cmp .locker-pin-boxes .box div:first-child,
.show-customer-locker-cmp .locker-pin-boxes .box div:nth-child(3) {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 0.9rem;
}

.show-customer-locker-cmp .locker-pin-boxes .box span {
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 5px;
}

.show-locker-close-modal-button-wrapper button {
  width: 100%;
  height: 50px;
  margin-top: 40px;
}

.boogie {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}
.locker-operation-button {
  height: 50px;
  width: 100px;
  margin: 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.1rem;
  border-width: 0px;
  color: white;
  -webkit-box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.75);
  cursor: pointer;
}
@media (hover: hover) {
  .locker-operation-button:hover {
    -webkit-box-shadow: 0px 2px 6px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 6px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 6px -4px rgba(0,0,0,0.75);
    border-width: 0px;
  }
}
#passCodeField {
  text-align: center;
  font-size:30px;
}
#passCodeField:focus {
  box-shadow: 0px 2px 5px skyblue;
}
#passCodeField.form-control.is-invalid {
  box-shadow: 0 0 4px 4px #DC3545;
}
.modal-dialog .modal-content {
  border: none;
  box-shadow: 1px 1px 43px 10px rgba(0,0,0,0.3);
}
.modal-dialog .modal-header {
  border-bottom-width: 0px;
}
.input-item-modal .modal-header button.close {
  margin-left: 0;
}
.signup-modal {
  max-width: 600px;
  color: #343434
}
.signup-modal .btn-submit {
  height: 60px;
  width: 100%;
}
#signup-confirmation-checkbox {
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  padding: 10px;
}
.modal-dialog h5.modal-title {
  font-size: 1.2rem;
  color: #343434;
  text-align: left;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
}
.modal-dialog .modal-title {
  color: white;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-pack: center;
  text-align: center;
}
.form-group label {
  font-weight: 600;
}
.form-check span {
  font-weight: 600;
  font-size: 1.2rem;
  vertical-align: top;
}
.submit-button-pretext {
  font-weight: bold;
  font-size: 16px;
  display: block;
  text-align: center;
  margin-top: 20px;
}
#terms-of-service {
  height: 60vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 4px outset gray;
  background-color: #f7f7f7;
  color:black;
  font-size: 20px;
  padding: 10px 20px;
}
#terms-of-service .tos-header {
  text-align: center;
}
#terms-of-service h1,
#terms-of-service h2,
#terms-of-service h3,
#terms-of-service h4,
#terms-of-service h5,
#terms-of-service h6 {
  font-weight: bold;
}
#terms-of-service p {
  font-size: 1rem;
  font-weight: 600;
}
#terms-and-conditions {
  margin-top: 3rem;
}
.order-inputs-inline-total-paid {
  display: flex;
  width: 100%;
  max-width: 360px;
  margin: auto;
}
.order-modal-phone-input .customer-thumb {
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.order-inputs-inline-total-paid-labels {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: auto;
}
.order-inputs-inline-total-paid-labels div:first-child {
  max-width: 240px;
  width: 240px;
}
.order-inputs-inline-total-paid-labels div:last-child {
  flex: 1 1;
}
.order-inputs-inline-total-paid .order-total-input {
  max-width: 240px;
  text-align: right;
  width: 100%;
}
.order-inputs-inline-total-paid .checkbox-is-paid {
  flex: 1;
  align-self: center;
}
.order-inputs-inline-total-paid .paid-toggle.btn.toggle.btn-xs.btn-primary,
.order-inputs-inline-total-paid .btn.toggle.paid-toggle.btn-xs.off.btn-danger {
  height: 80px !important;
  width: 120px !important;
  border-radius: 10px !important;
}

.confirm-order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.confirm-order-btn .main-text {
  margin-right: 5px;
}

.settings-header {
  font-weight: bold;
  font-size: 40px;
}
.setting-list {
  cursor: pointer;
}
@media (hover: hover) {
  .setting-list:hover {
    text-decoration: underline;
  }
}
#locker-form-text {
  color: red !important;
}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.toggle-off {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}
.toggle.off {
    border-color: rgba(0, 0, 0, .25);
}
.toggle-wrapper {
  max-width: 450px;
  margin: auto;
  text-align: center;
}
.toggle-handle {
  background-color: white;
  border: thin rgba(0, 0, 0, .25) solid;
}
.btn.toggle.btn-xs.btn-primary, .btn.toggle.btn-xs.off.btn-danger {
  width: 150px !important;
  border-radius: 25px !important;
  margin: 0 20px !important;
  height: 30px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.toggle-wrapper .btn.toggle-off.btn-xs.btn-danger {
  background: #FAB12D;
  border-color: #FAB12D;
}
.toggle-wrapper .btn.toggle.btn-xs.off.btn-danger {
  background: #FAB12D;
}

.btn.toggle.btn-xs.btn-primary p, .btn.toggle.btn-xs.off.btn-danger p {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.3px;
}
span.btn.toggle-on, span.btn.toggle-off {
  padding-top: 3px !important;
  padding-bottom: 0 !important;
}
span.toggle-handle {
  padding-left: .5rem;
  padding-right: .5rem;
}
.loading-cmp {
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
  align-items: center;
}
.confirm-timer-cmp {
  min-width: 40px;
  max-width: 50px;
  text-align: center;
  opacity: 0.5;
  display: inline-block;
}
.timer-cmp {
  font-size: 0.6rem;
  font-weight: 600;
  opacity: 0.5;
}
.lockerButton .timer-cmp {
  opacity: 1;
  position: absolute;
  left: 15px;
  bottom: 15px;
}
/* Toolbar on runner page  */
#Popover1 {
  background: #e9e9e9;
  text-decoration: none;
  color: rgba(0,0,0,0.5);
  width: 30px;
  height: 30px;
  padding-top: 4px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
}
#refresh-button .lockers-refresh-button,
.toggle-wrapper #Popover1 img {
  transition: .5s;
  -webkit-transition: .5s;
}
#refresh-button .lockers-refresh-button {
  width: 40px;
}
#refresh-button .lockers-refresh-button:hover,
.toggle-wrapper #Popover1 img:hover {
  transform: rotate(180deg);
}
#refresh-button,
.toggle-wrapper #Popover1 {
  -webkit-appearance: none;
  padding: 0;
  background: none;
  border: none;
  height: initial;
  width: initial;
}
.toggle-wrapper #Popover1:focus,
#refresh-button {
  outline: 0;
}
.lockerButton:focus {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.settings-back-button {
  height: 24px;
  width: 30px;
  cursor: pointer;
  margin-bottom: 9px;
  margin-right: 10px;
}
/* Customer Name input */
.customer-input-name button.btn-submit {
  height: 60px;
  width: 100%;
}
.customer-input-name button.btn-danger {
  background: #fa324d;
  border-color: #fa324d;
}
.customer-input-name-thank-you .thank-you-row {
  margin: 0 0 30px;
  font-weight: 600;
  color: #343434;
}
.gql-error {
  color: red;
  font-size: 18px;
  font-weight: bold;
  display: block;
  text-align: center;
}

@media screen and (max-width: 430px) {
  .boogie {
    font-size: 1.4rem;
  }
  .locker-operation-button {
    width: 110px;
  }
}

ul.details-list {
  list-style: none;
  padding-left: 0px;
  margin: 0px;
  width: 100%;
}

ul.details-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
}

ul.details-list li:nth-child(even) {
  background: #f8fdff;
}

ul.details-list li span {
  font-size: 0.6rem;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: bold;
  color: #757575;
}
ul.details-list li .value {
  font-size: 0.8rem;
}
ul.details-list li.major .value {
  font-size: 0.9rem;
  color: #343434;
  font-weight: bold;
}
ul.details-list li .value {
  font-size: 0.7rem;
}

.pin-code-field-wrapper {
  padding: 40px 30px;
}

.pin-lg-value {
  display: flex;
  font-size: 2.4rem;
  letter-spacing: 10px;
  font-weight: bold;
  width: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  justify-content: center;
  padding: 10px;
  color: #343434;
}

.pin-lg-value img {
  width: 50px;
}

.steps-nav.floated {
  position: absolute;
  right: -60px;
  top: 0px;
}

.steps-nav .nav-item {
  position: relative;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 8px -2px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 8px -2px rgba(0,0,0,1);
  box-shadow: 0px 0px 8px -2px rgba(0,0,0,1);
}
.steps-nav .nav-item:hover {
  opacity: 0.9;
  width: 46px;
  height: 46px;
  margin: 17px auto;
  cursor: pointer;
}
.steps-nav .nav-item.active {
  width: 50px;
  height: 50px;
  margin: 15px 0px;
  color: #fff;
  background: #F03434;
}

.steps-nav .nav-item .error-mark {
  position: absolute;
  color: #F03434;
  right: -15px;
  top: 50%;
  margin-top: -20px;
  font-size: 2rem;
  font-weight: bold;
}

.error-msg {
  color: #F03434;
  font-size: 0.9rem;
  text-align: center;
  padding: 5px 0px;
  display: block;
  text-align: center;
}

.pickup-greetings {
  font-weight: 600;
}

.forgot-passcode-wrapper {
  padding: 15px 0px;
  text-align: center;
}
.forgot-passcode-wrapper .btn.btn-default {
  width: 100%;
  height: 50px;
}

.confirm-step-content {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-step-content h2 {
  font-weight: 800;
  letter-spacing: 1px;
  text-align: center;
  color: #343434;
  font-size: 2em;
  margin-bottom: 20px;
}

.confirm-step-content img {
  max-width: 150px;
  margin-top: 20px;
  margin-bottom: 30px;
}

#callback {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}
.locker-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 200px;
}
.locker-column button {
  flex: 1;
}
#page-404 {
  position: 'absolute';
  left: 0;
  right: 0;
  top: '240px';
  text-align: 'center';
}
/* Loading Spinner */
.sk-circle {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1s infinite ease-in-out both;
          animation: sk-circleBounceDelay .8s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.sk-circle .sk-circle1:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.input-item-modal.stripe-modal .modal-header,
.modal-dialog.stripe-modal .modal-header {
  padding: 1rem 0 0;
}
.input-item-modal.stripe-modal .modal-header h5,
.modal-dialog.stripe-modal .modal-header h5 {
  margin: 0 auto;
  font-size: 15px;
  font-weight: bold;
}
.stripe-modal .error {
  font-size: 14px;
  color: red;
  font-weight: 600;
}
.register-stripe h4 {
  color: black;
  font-weight: 600;
}
.register-stripe .error {
  color: #f03434;
  font-weight: 600;
}

.modal-embedded {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
}

.modal-embedded .modal-header {
  padding-top: 30px;
  justify-content: center;
  align-items: center;
}

.modal-embedded .modal-header .modal-title {
  text-align: center;
  font-size: 1.2rem;
  color: #343434;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
}

.modal-embedded .form-group label {
  color: #343434;
}

.pc-signup .form-check {
  margin-top: 30px;
}

.pc-signup .form-check label > span,
.pc-signup .form-check label > span > span {
  font-size: 0.9rem;
  line-height: 1rem;
}

.pc-signup .form-check label > span > span {
  color: #343434;
}

.pc-signup .submit-button-pretext {
  margin-bottom: 30px;
  font-weight: normal;
}

.pc-signup .btn-submit.btn {
  width: 100%;
}

.temp-class-verify-modal ul.details-list li .value {
  font-size: 1rem;
}

.modal-backdrop.show {
  opacity: .8 !important;
}