input.large-input {
  font-size: 1.6rem;
  height: 80px;
}
.form-control[readonly] {
  background-color: initial;
}
label.large-label {
  font-size: 1.2rem;
  letter-spacing: 1px;
}
label.large-label.faint {
  font-weight: normal;
}
input.pin-input {
  text-align: center;
  letter-spacing: 6px;
}
input::placeholder {
  color: #ededed !important;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #ededed !important;
}

input::-ms-input-placeholder {
  color: #ededed !important;
}

input:-ms-input-placeholder {
  color: #ededed !important;
}

input::-ms-input-placeholder {
  color: #ededed !important;
}
.btn {
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
.btn-submit,
.btn-submit.btn-secondary {
  background: #60D470;
  border-color: #60D470;
}

/* CHECKBOX STYLE */
.label__checkbox {
  display: none;
}

.label__check {
  display: inline-block;
  border-radius: 50%;
  border: 5px solid rgba(0,0,0,0.1);
  background: white;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border .3s ease;
}

.label__check i.icon {
  opacity: 0.2;
  font-size: ~'calc(1rem + 1vw)';
  color: transparent;
  transition: opacity .3s .1s ease;
}

.label__check i.icon.dark {
  color: rgba(0,0,0,0.1);
  opacity: 1;
}

.label__checkbox:checked + .label__text .label__check {
  animation: check .3s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;
}

.label__checkbox:checked + .label__text .label__check .icon {
  opacity: 1;
  color: white;
  -webkit-text-stroke: 0;
  animation: icon .2s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
}

@keyframes icon {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
}

@keyframes check {
  0% {
    border-width: 5px;
  }
  10% {
    opacity: 0.1;
    background: rgba(0,0,0,0.2);
    border-width: 15px;
  }
  12% {
    opacity: 0.4;
    background: rgba(0,0,0,0.1);
    border-width: 0;
  }
  50% {
    background: #00d478;
    border: 0;
    opacity: 0.6;
  }
  100% {
    background: #00d478;
    border: 0;
    opacity: 1;
  }
}
